<template>
    <div class="w-full p-4" v-if="loaded" :key="questionsComponentKey">
      <div class="grid grid-cols-3 gap-4">
        <div class="pt-2">
            <label class="font-semibold text-brand pb-1">Стандарт вопроса</label>
            <div class="w-full">
                <select v-model="parentTourId" class="p-1 border-solid border-brand-light border w-full rounded">
                    <option :value="1" class="bg-brand rounded text-white hover:bg-brand-dark">1</option>
                    <option :value="2" class="bg-brand rounded text-white hover:bg-brand-dark">2</option>
                    <option :value="3" class="bg-brand rounded text-white hover:bg-brand-dark">3</option>
                    <option :value="4" class="bg-brand rounded text-white hover:bg-brand-dark">4</option>
                    <option :value="5" class="bg-brand rounded text-white hover:bg-brand-dark">5</option>
                    <option :value="6" class="bg-brand rounded text-white hover:bg-brand-dark">6</option>
                    <option :value="7" class="bg-brand rounded text-white hover:bg-brand-dark">7</option>
                </select>

            </div>
        </div>
        <div class="pt-2">
          <label class="font-semibold text-brand pb-1">Механика воспроизведения</label>
          <div class="w-full">
            <select v-model="parentMechanicType" class="p-1 border-solid border-brand-light border w-full rounded">
              <option value="regular" class="bg-brand rounded text-white hover:bg-brand-dark">Регулярная</option>
              <option value="music" class="bg-brand rounded text-white hover:bg-brand-dark">Музыка</option>
              <option value="video" class="bg-brand rounded text-white hover:bg-brand-dark">Видео</option>
              <option value="blitz" class="bg-brand rounded text-white hover:bg-brand-dark">Блиц</option>
              <option value="handout" class="bg-brand rounded text-white hover:bg-brand-dark">Раздатка</option>
            </select>

          </div>
        </div>
        <div class="pt-2">
          <label class="font-semibold text-brand pb-1">Галочка уверенности</label>
          <div class="w-full">
            <select v-model="parentNeedConfidence" class="p-1 border-solid border-brand-light border w-full rounded">
              <option value="true" class="bg-brand rounded text-white hover:bg-brand-dark">Да</option>
              <option value="false" class="bg-brand rounded text-white hover:bg-brand-dark">Нет</option>
            </select>

          </div>
        </div>
        <div class="pt-2">
          <label class="font-semibold text-brand pb-1">Тестовый тур</label>
          <div class="w-full">
            <select v-model="parentIsTest" class="p-1 border-solid border-brand-light border w-full rounded">
              <option value="true" class="bg-brand rounded text-white hover:bg-brand-dark">Да</option>
              <option value="false" class="bg-brand rounded text-white hover:bg-brand-dark">Нет</option>
            </select>

          </div>
        </div>
        <div class="pt-2">
          <label class="font-semibold text-brand">Отображаемое значение</label>
          <div class="w-full">
            <input class="p-1 border-solid border-brand-light border w-full rounded"
                   type="number"
                   v-model="parentDisplayedNumber">
          </div>
        </div>
        <div class="pt-2">
          <label class="font-semibold text-brand">Название тура</label>
          <div class="w-full">
            <input class="p-1 border-solid border-brand-light border w-full rounded"
                   v-model="parentTourName">
          </div>
        </div>
        <!-- <div class="pt-2 pl-2 ">
            <label class="font-semibold text-brand pb-1">Порядковый номер</label>
            <div class="w-full">
                <input type="number" v-model="tour" class="p-1 inp-file border-solid border-brand-light border w-full rounded" min="0" max="100" name="" id="">
            </div>
        </div> -->
        </div>
        <table class="w-full mt-2">
            <thead>
            <tr>
                <th>
                    <div class="flex">
                        <a href="" class="text-grey-darkest no-underline hover:text-grey mx-auto my-auto"
                           title="Выбрать всё на этой странице"
                           @click.prevent="selectAll(questions)">
                            <font-awesome-icon v-if="checked" class="w-6"
                                               :icon="['fas', 'angle-up']"></font-awesome-icon>
                            <font-awesome-icon v-else class="w-6" :icon="['fas', 'angle-down']"></font-awesome-icon>
                        </a>
                        <tag-select-panel @tagColumns="updateColumns"></tag-select-panel>
                    </div>
                </th>
                <th>№</th>
                <th>Тип генерации</th>
                <th>Формат вопроса</th>
                <th v-if="tagColumns && tagColumns.complexity.required && tagColumns.complexity.value"
                    v-text="'Сложность'"></th>
                <th v-if="tagColumns && tagColumns.question_type.required && tagColumns.question_type.value"
                    v-text="'Тип вопроса'"></th>
                <th v-if="tagColumns && tagColumns.theme.required && tagColumns.theme.value"
                    v-text="'Текст'"></th>
                <th v-if="tagColumns && tagColumns.includedRegions.required && tagColumns.includedRegions.value"
                    v-text="'Регион'"></th>
                <th v-if="tagColumns && tagColumns.hit.required && tagColumns.hit.value"
                    v-text="'Хит'"></th>
                <th v-if="tagColumns && tagColumns.direction.required && tagColumns.direction.value"
                    v-text="'Стиль'"></th>
                <th v-if="tagColumns && tagColumns.musical_answer_type.required && tagColumns.musical_answer_type.value"
                    v-text="'Музыка'"></th>
                <th v-if="tagColumns && tagColumns.music_region.required && tagColumns.music_region.value"
                    v-text="'Локал'"></th>
                <!--<th v-if="tagColumns && tagColumns.is_special.required && tagColumns.is_special.value"-->
                    <!--v-text="'Спец. игра'"></th>-->
                <th v-if="tagColumns && tagColumns.picture.required && tagColumns.picture.value"
                    v-text="'Картинки'"></th>
                <th v-if="tagColumns && tagColumns.blitz.required && tagColumns.blitz.value"
                    v-text="'Блиц'"></th>
                <th v-if="tagColumns && tagColumns.company_type.required && tagColumns.company_type.value"
                    v-text="'Компании'"></th>
                <th title="Замена вопроса">
                    <font-awesome-icon class="text-grey text-base" :icon="['fas', 'arrows-alt-h']"></font-awesome-icon>
                </th>
                <th v-text="'№ спец вопроса'"></th>
                <th v-text="'Фон'"></th>
                <th title="Просмотр">
                    <font-awesome-icon class="text-grey text-base" :icon="['fas', 'eye']"></font-awesome-icon>
                </th>
                <th class="border-r-0 ">
                    <a href="" class="text-grey-darkest no-underline hover:text-grey"
                       title="Удалить выбранные"
                       @click.prevent="remove(selectedQuestions)">
                        <trash></trash>
                    </a>
                </th>
            </tr>
            </thead>
            <tbody>
            <template-question v-for="(question, key) in questions" :value="question" :key="key"
                               :question="question" :tag-columns="tagColumns"
                               :index="key"
                               :tags="tags"
                               :game-type-id="gameTypeId"
                               :game-topic-id="gameTopicId"
                               v-if="tags"
                               :checked="selectedQuestions.indexOf(question.id) !== -1"
                               :parentTourId="parentTourId"
                               :slide-backgrounds="slideBackgrounds"
                               @removeQuestion="remove([question.id])"
                               @selectQuestion="selectQuestion(question.id)"
                               @tagsSelected="addTagsInfo(key, $event)"
                               @generationTypeSelected="addQuestionGenerationType(key, $event)"
                               @backgroundSelected="addSlideBackground(key, $event)"
                               @selected="addSpecialQuestionId(key, $event)"
                               @updated="fetchQuestions" @replaced="fetchQuestions" @failed="failAlert"
            ></template-question>
            </tbody>
        </table>
        <div class="flex justify-start mt-4">
            <button type="button" @click.prevent="addQuestion" class="no-underline circle bg-brand-red text-white pb-1">
                +
            </button>
        </div>
    </div>
</template>

<script>
import Trash from '../../icons/trash'
import TagSelectPanel from '../../components/TagSelectPanel'
import TemplateQuestion from './question'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import swal from 'sweetalert2'
export default {
  inject: ['templateTours'],
  props: ['tourStandart', 'start_from_zero', 'gameType', 'subType', 'gameTypeId', 'gameTopicId', 'slideBackgrounds'],
  name: 'TemplateQuestions',
  components: { TemplateQuestion, TagSelectPanel, Trash },
  computed: {
    id () {
      return this.$route.params.id
    },

    isCorpLeague () {
      return this.gameType.toLowerCase().includes('корпорати') || this.subType.toLowerCase().includes('корпорати')
    },
    // tour () {
    //   return this.$route.params.tour
    // },
    tourQuestions () {
      if (this.start_from_zero) {
        return this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].questions : []
      }
      return this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].questions : []
    },
  },
  watch: {
    questions: {
      handler (val) {
        this.$emit('added', {
          questions: val,
          tour_number: this.start_from_zero ? this.tour : this.tour - 1,
          tour_id: this.parentTourId,
          mechanic_id: this.parentMechanicId,
          mechanic_type: this.parentMechanicType,
          need_confidence: this.parentNeedConfidence,
          is_test: this.parentIsTest,
          displayed_number: this.parentDisplayedNumber,
          tour_name: this.parentTourName
        })
      },
      deep: true,
    },
    parentNeedConfidence (val) {
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: val,
        is_test: this.parentIsTest,
        displayed_number: this.parentDisplayedNumber,
        tour_name: this.parentTourName
      })
    },
    parentDisplayedNumber (val) {
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: this.parentNeedConfidence,
        is_test: this.parentIsTest,
        displayed_number: val,
        tour_name: this.parentTourName
      })
    },
    parentTourName (val) {
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: this.parentNeedConfidence,
        is_test: this.parentIsTest,
        displayed_number: this.parentDisplayedNumber,
        tour_name: val
      })
    },
    parentMechanicType (val) {
      this.parentMechanicId = this.getMechanicId(val)
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: val,
        need_confidence: this.parentNeedConfidence,
        is_test: this.parentIsTest,
        displayed_number: this.parentDisplayedNumber,
        tour_name: this.parentTourName
      })
    },
    parentIsTest (val) {
      this.parentMechanicId = this.getMechanicId(val)
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: this.parentNeedConfidence,
        is_test: val,
        displayed_number: this.parentDisplayedNumber,
        tour_name: this.parentTourName
      })
    },
    parentTourId (val, old) {
      let tour = ''
      if (this.start_from_zero) {
        tour = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].tour_id : ''
      } else {
        tour = this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].tour_id : ''
      }
      if (old || !tour) {
        this.parentMechanicType = this.getMechanicType(val)
        this.parentNeedConfidence = val ? val.toString() === '7' : false;
      }
      this.redesignTagColumns(val)
    },
    tour (n, o) {
      this.$emit('tour_chane', { new_id: Number(n), old_id: Number(o) })
    },
  },
  created () {
    this.questions = this.tourQuestions
    this.checkQuestionsStructure()
    this.tourId = this.tourStandart
    if (this.start_from_zero) {
      this.parentTourId = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].tour_id : ''
      this.parentMechanicId = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].mechanic_id : ''
      this.parentMechanicType = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].mechanic_type : ''
      this.parentDisplayedNumber = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].displayed_number : ''
      this.parentTourName = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].tour_name : ''
      this.parentNeedConfidence = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].need_confidence : ''
      this.parentIsTest = this.templateTours.data.tours[this.tour] ? this.templateTours.data.tours[this.tour].is_test : ''
    } else {
      this.parentTourId = this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].tour_id : ''
      this.parentMechanicId = this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].mechanic_id : ''
      this.parentMechanicType = this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].mechanic_type : ''
      this.parentDisplayedNumber = this.templateTours.data.tours[this.tour -1] ? this.templateTours.data.tours[this.tour -1].displayed_number : ''
      this.parentTourName = this.templateTours.data.tours[this.tour -1] ? this.templateTours.data.tours[this.tour -1].tour_name : ''
      this.parentNeedConfidence = this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].need_confidence : ''
      this.parentIsTest = this.templateTours.data.tours[this.tour - 1] ? this.templateTours.data.tours[this.tour - 1].is_test : ''
    }

    axios.get(`/api/tags`).then((data) => {
      this.tags = data.data
      this.loaded = true
    })
    if (this.gameType || this.subType) {
      if (this.templateTours.data.tours[this.tour - 1] && !this.start_from_zero) {
        this.redesignTagColumns(this.templateTours.data.tours[this.tour - 1].tour_id)
      } else if (this.templateTours.data.tours[this.tour] && this.start_from_zero) {
        this.redesignTagColumns(this.templateTours.data.tours[this.tour].tour_id, 'vp')
      }
    }
  },
  data () {
    return {
      questionsComponentKey: 0,
      questions: [],
      specialQuestions: [],
      selectedQuestions: [],
      tourId: '',
      tour: this.$route.params.tour,
      parentTourId: '',
      parentMechanicId: '',
      parentMechanicType: '',
      parentNeedConfidence: false,
      parentIsTest: false,
      parentDisplayedNumber: '',
      parentTourName: '',
      tagColumns: {
        show_additional: true,
        city: {
          required: false,
          value: false,
        },
        city_search_type: {
          required: false,
          value: false,
        },
        game_type_id: {
          required: false,
          value: false,
        },
        main_type: {
          required: false,
          value: false,
        },
        sub_type: {
          required: false,
          value: false,
        },
        topic: {
          required: false,
          value: false,
        },
        game_subtype_id: {
          required: false,
          value: false,
        },
        game_topic_id: {
          required: false,
          value: false,
        },
        pack_number: {
          required: false,
          value: false,
        },
        slide_type_id: {
          required: false,
          value: false,
        },
        tour_id: {
          required: false,
          value: false,
        },
        is_special: {
          required: false,
          value: false,
        },
        is_adaptive: {
          required: false,
          value: false,
        },
        theme: {
          required: true,
          value: true,
        },
        question_type: {
          required: true,
          value: false,
        },
        complexity: {
          required: true,
          value: true,
        },
        includedRegions: {
          required: true,
          value: true,
        },
        excludedRegions: {
          required: false,
          value: true,
        },
        hit: {
          required: true,
          value: false,
        },
        source: {
          required: false,
          value: false,
        },
        direction: {
          required: true,
          value: false,
        },
        musical_answer_type: {
          required: true,
          value: false,
        },
        music_region: {
          required: true,
          value: false,
        },
        picture: {
          required: true,
          value: false,
        },
        blitz: {
          required: true,
          value: false,
        },
        company_type: {
          required: true,
          value: false,
        },
        company_name: {
          required: false,
          value: false,
        },
      },
      checked: false,
      tags: [],
      loaded: false,
      defaultQuestionTagsStructure: {
        'slide_type_id': {
          'type': 'no_matter',
          'ids': [],
        },
        'theme': {
          'type': 'no_matter',
          'ids': [],
        },
        'picture': {
          'type': 'no_matter',
          'ids': [],
        },
        'blitz': {
          'type': 'no_matter',
          'ids': [],
        },
        'company_type': {
          'type': 'no_matter',
          'ids': [],
        },
        'question_type': {
          'type': 'no_matter',
          'ids': [],
        },
        'complexity': {
          'type': 'no_matter',
          'ids': [],
        },
        'includedRegions': {
          'type': 'no_matter',
          'ids': [],
        },
        'hit': {
          'type': 'no_matter',
          'ids': [],
        },
        'direction': {
          'type': 'no_matter',
          'ids': [],
        },
        'musical_answer_type': {
          'type': 'no_matter',
          'ids': [],
        },
        'music_region': {
          'type': 'no_matter',
          'ids': [],
        },
      },
    }
  },
  provide () {
    return {
      tagColumns: this.tagColumns,
    }
  },
  methods: {
    redesignTagColumns (tourId) {
      if (tourId == 5) {
        this.tagColumns.question_type.required = true
        this.tagColumns.picture.required = true
        this.tagColumns.picture.value = true

        this.tagColumns.theme.required = false
        this.tagColumns.blitz.required = false
        this.tagColumns.company_type.required = false
        this.tagColumns.hit.required = false
        this.tagColumns.direction.required = false
        this.tagColumns.musical_answer_type.required = false
        this.tagColumns.music_region.required = false
        this.tagColumns.show_additional = false
      } else if (tourId == 7) {
        this.tagColumns.question_type.required = true
        this.tagColumns.blitz.required = true
        this.tagColumns.blitz.value = true

        this.tagColumns.theme.required = false
        this.tagColumns.picture.required = false
        this.tagColumns.company_type.required = false
        this.tagColumns.hit.required = false
        this.tagColumns.direction.required = false
        this.tagColumns.musical_answer_type.required = false
        this.tagColumns.music_region.required = false
        this.tagColumns.show_additional = false
      } else if ((tourId == 1 || tourId == 4) && this.isCorpLeague) {
        this.tagColumns.question_type.required = true
        this.tagColumns.company_type.required = true
        this.tagColumns.company_type.value = true

        this.tagColumns.theme.required = false
        this.tagColumns.picture.required = false
        this.tagColumns.blitz.required = false
        this.tagColumns.hit.required = false
        this.tagColumns.direction.required = false
        this.tagColumns.musical_answer_type.required = false
        this.tagColumns.music_region.required = false
        this.tagColumns.show_additional = false
      } else if (tourId == 3) {
        this.tagColumns.question_type.required = false
        this.tagColumns.picture.required = false
        this.tagColumns.theme.required = false
        this.tagColumns.blitz.required = false
        this.tagColumns.company_type.required = false

        this.tagColumns.hit.required = true
        this.tagColumns.direction.required = true
        this.tagColumns.musical_answer_type.required = true
        this.tagColumns.musical_answer_type.value = true
        this.tagColumns.music_region.required = true
        this.tagColumns.show_additional = true
      } else {
        this.tagColumns.question_type.required = true
        this.tagColumns.picture.required = false
        this.tagColumns.blitz.required = false
        this.tagColumns.company_type.required = false
        this.tagColumns.theme.required = true
        this.tagColumns.theme.value = true

        this.tagColumns.hit.required = false
        this.tagColumns.direction.required = false
        this.tagColumns.musical_answer_type.required = false
        this.tagColumns.music_region.required = false
        this.tagColumns.show_additional = false
      }
    },
    addQuestion () {
      this.questions.push({
        id: this.questions.length + 1,
        generationType: 'random',
        slideBackgroundUuid: null,
        tags: this.defaultQuestionTagsStructure,
      })
    },
    addTagsInfo (key, params) {
      // console.log(key , params)
      this.questions[key].tags = params
    },
    addQuestionGenerationType (key, type) {
      this.questions[key].generationType = type
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: this.parentNeedConfidence,
        is_test: this.parentIsTest,
        displayed_number: this.parentDisplayedNumber,
        tour_name: this.parentTourName
      })
    },
    addSlideBackground (key, slideBackgroundUuid) {
      this.questions[key].slideBackgroundUuid = slideBackgroundUuid
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: this.parentNeedConfidence,
        is_test: this.parentIsTest,
        displayed_number: this.parentDisplayedNumber,
        tour_name: this.parentTourName
      })
    },
    addSpecialQuestionId (key, id) {
      this.questions[key].specialQuestionId = id
      this.$emit('added', {
        questions: this.questions,
        tour_number: this.start_from_zero ? this.tour : this.tour - 1,
        tour_id: this.parentTourId,
        mechanic_id: this.parentMechanicId,
        mechanic_type: this.parentMechanicType,
        need_confidence: this.parentNeedConfidence,
        is_test: this.parentIsTest,
        displayed_number: this.parentDisplayedNumber,
        tour_name: this.parentTourName
      })
    },
    updateColumns (columns) {
      this.tagColumns = columns
    },
    checkQuestionsStructure () {
      console.log('here')
      this.questions.forEach((question) => {
        for (let key in this.defaultQuestionTagsStructure) {
          if (this.defaultQuestionTagsStructure.hasOwnProperty(key)) {
            if (!(key in question.tags)) {
              question.tags[key] = cloneDeep(this.defaultQuestionTagsStructure[key])
            }
          }
        }
      })
    },
    selectAll (questions) {
      let questionIds = map(questions, 'id')
      if (this.selectedQuestions.length === questionIds.length) {
        this.clearSelected()
      } else {
        this.selectedQuestions = questionIds
        this.checked = true
      }
    },
    selectQuestion (id) {
      if (this.selectedQuestions.indexOf(id) !== -1) {
        this.dropSelected(this.selectedQuestions, id)
      } else {
        this.selectedQuestions.push(id)
      }
    },
    clearSelected () {
      this.selectedQuestions = []
      this.checked = false
    },
    dropSelected (array, id) {
      let index = array.indexOf(id)
      if (index !== -1) array.splice(index, 1)
    },
    remove (ids) {
      if (ids.length === 0) {
        swal('Выберите вопросы!', '', 'error')
        return
      }
      let message = 'Данные о структуре вопроса будут удалены, это действие невозможно отменить!'
      this.confirmDelete(message).then(() => {
        this.dropFromArray(ids)
        swal('Удалено!', 'Вопрос удален.', 'success')
        this.clearSelected()
        this.forceRerender()
        this.fetchQuestions()
      })
    },
    forceRerender () {
      this.questionsComponentKey += 1
    },
    dropFromArray (ids) {
      ids.forEach((id) => {
        this.questions = this.questions.filter(function (obj) {
          return obj.id !== id
        })
      })
      this.questions.forEach((item, index) => {
        item.id = index + 1
      })
    },
    confirmDelete (message) {
      return new Promise(function (resolve) {
        swal({
          title: 'Вы уверены?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да удалить!',
          cancelButtonText: 'Отмена',
        }).then((result) => {
          if (result.value) {
            resolve(result)
          }
        })
      })
    },
    fetchQuestions () {
      axios.get(`/api/game-questions?sort=question_number&filter[game_id]=${this.id}&filter[tour_id]=${this.tour}`).then((data) => {
        this.specialQuestions = data.data.data
      })
      this.loaded = true
    },
    failAlert () {
      swal('Ошибка!', 'Не удалось осуществить замену', 'error')
      this.fetchQuestions()
    },
    getMechanicType (tourId) {
      let types = {
        1: 'regular',
        2: 'regular',
        3: 'music',
        4: 'regular',
        5: 'regular',
        6: 'regular',
        7: 'blitz',
      }
      return types[tourId]
    },
    getMechanicId (type) {
      let types = {
        'regular': 1,
        'music': 3,
        'video': 5,
        'blitz': 7,
        'handout': 6
      }
      return types[type]
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }

    .numbers .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        font-size: 25px;

    }

    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
    .inp-file {
      height: 33px;
      padding: .25rem !important;
      padding-left: 10px;
    }
    .grid{
      display: grid;
    }
    .grid-cols-3{
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 1rem;
    }
    input , select{
      height: 35px;
    }

</style>
